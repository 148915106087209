<template>
<section class="brandinfo-wrapper">
    <h1 class="header-title">编辑动态</h1>

    <!-- form -->
    <el-form class="form-content not-thumb" ref="form" :model="form" :rules="rules" label-width="100px" label-position="left">
        <!-- <el-form-item label="标题名称" prop="name">
            <el-input v-model="form.name" maxlength="30" placeholder="最多输入30个字符"></el-input>
        </el-form-item> -->
        <el-form-item label="上传图片" prop="mediaUrl">
            <p class="tip">支持png，jpeg，jpg格式，最多可上传9张；图片大小不能超过3MB。</p>
            <el-upload
                ref="uploader"
                action=""
                :http-request="uploadHandler"
                list-type="picture-card"
                :limit="9"
                accept="image/png,image/jpeg,image/jpg"
                :before-upload="beforeUploadHandler"
                :file-list="fileList"
                :on-change="logoChangeHandler"
                :on-preview="handlePictureCardPreview"
                :on-remove="handleRemove">
                <p class="upload-custom flex flex-column align-center flex-center">
                    <i class="el-icon-plus el-icon"></i><br/>
                    <span >添加上传图片</span>
                </p>
            </el-upload>
        </el-form-item>
        <el-form-item label="动态详情" prop="text">
            <textarea class="textarea-content thumb-bar" v-model="form.text" ></textarea>
        </el-form-item>
        <el-form-item>
            
        </el-form-item>
    </el-form>

    <div class="footer-btn">
        <el-button @click="cancelHandler">取消</el-button>
        <el-button type="primary" @click="saveHandler">保存</el-button>
    </div>

    <!-- 图片放大 -->
    <el-dialog :visible.sync="dialogVisible">
        <img width="100%" :src="dialogImageUrl" alt="">
    </el-dialog>
</section>
</template>

<script>
import utils from '@/utils/utils.js';
let $uploader;
let id;
export default {
    data(){
        return {
            form:{
                name:'',
                mediaUrl:[],
                text:''
            },
            rules:{
                // name:[{required: true, message: '请输入动态标题', trigger: 'blur'}],
                mediaUrl:[{required: false, message: '请上传动态图片', trigger: 'change'}],
                text:[{required: true, message: '请输入动态详情', trigger: 'blur'}],
            },
            fileList:[],
            dialogVisible:false,
            dialogImageUrl:''
        }
    },
    created(){
        id = this.$route.query.id;
        if(!id){
            this.$message.error('请从正确的路径进入');
            return;
        }
        this.getDetail();
    },
    mounted(){
        $uploader = this.$refs.uploader.$el.querySelector('.el-upload--picture-card');
        console.log($uploader);
    },
    watch:{
        fileList(val){
            if(!$uploader) return;
            if(val.length<9){
                $uploader.style.display = 'inline-block';
            }else{
               $uploader.style.display = 'none';
            }
        }
    },
    methods:{
        async getDetail(){
            const rs = await this.$https.cloud({
                action:'activity',
                type:'detail', 
                formData:{
                    _id: id
                }
            });
            if(rs.code==0){
                try{

                    this.form.text = rs.data.data[0].text;
                    if(!rs.data.data[0].mediaUrl||rs.data.data[0].mediaUrl.length==0) return;

                    const imgList = [];
                    rs.data.data[0].mediaUrl.forEach(item=>{
                        imgList.push({fileid: item});
                    });
                    const fileList = await this.$https.getImagePath(imgList);
                    fileList.forEach((item,i)=>{
                        this.fileList.push({
                            url: item.download_url,
                            fileID: item.fileid,
                        });
                    });
                }catch(e){
                    this.$message.error('发生未知错误');
                }
            }else{
                this.$message.error('获取动态失败');
            }
        },
        handlePictureCardPreview(file){
            this.dialogVisible = true;
            this.dialogImageUrl = file.url;
        },
        beforeUploadHandler(file){
            const isLt3M = file.size / 1024 / 1024 < 3;
            if (!isLt3M) {
            this.$message.error('上传图片大小不能超过 3MB!');
            }
            return isLt3M;
        },
        handleRemove(file,fileList){
            console.log(file,fileList);
            this.fileList.forEach((item,i)=>{
                if(item.uid == file.uid){
                    let data = this.fileList.splice(i,1);
                    console.log(data);
                    this.$https.delImage([data[0].fileID]);
                }
            })
        },
        async uploadHandler(file,fileList){
            const localPath = utils.Img_URL(file.file);
            const rs = await this.$https.uploadImage('activity',file.file);
            console.log(rs)
            if(!rs) return;
            this.fileList.push({
                url: localPath,
                fileID: rs,
                uid: file.file.uid
            });
            console.log(this.fileList);
        },
        logoChangeHandler(file,fileList){
            console.log(file,fileList)
        },
        async saveHandler(){
            this.form.mediaUrl = [];
            this.fileList.forEach(item=>{
                this.form.mediaUrl.push(item.fileID);
            });
            this.$refs.form.validate( async valid=>{
                if(!valid) return;
                const rs = await this.$https.cloud({
                    action:'activity',
                    type:'edit', 
                    formData:{
                        _id: id,
                        text: this.form.text,
                        mediaUrl: this.form.mediaUrl
                    }
                });
                if(rs.code==0){
                    this.$message.success('动态修改成功');
                    this.$router.push({name:'dynamicManager'});
                }else{
                    this.$message.error('动态修改失败');
                }
            })
        },
        cancelHandler(){
            this.$router.push({name:'dynamicManager'})
        }
        
    }
}
</script>

<style lang="scss" scoped>
.brandinfo-wrapper {
    height: 100%;
    padding: 10px;
    background: #fff;
    position: relative;

    .header-title {
        font-size: 16px;
        line-height: 22px;
        padding: 13px 0 12px 0;
        border-bottom: 1px solid #D9D9D9;
    }

    .form-content {
        max-width: 100%;
        margin-top: 40px;
        max-height: calc(100% - 170px);
        overflow-y: scroll;

        .textarea-content {
            width: 500px;
            height: 300px;
            border: 1px solid #ccc;
            border-radius: 5px;
            padding: 10px;
            overflow-y: scroll;
            // white-space: pre-wrap;
        }
    }

}
</style>